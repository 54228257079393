/** This file is auto generated by convertSvgToJsx.ts */

import React from 'react'
const close = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M3 13 13 3m0 10L3 3"
    />
  </svg>
)
export default close
