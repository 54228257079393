/** This file is auto generated by convertSvgToJsx.ts */

import React from 'react'
const exclamation = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M8 10.412V2m-.177 11.519h.346m.08 0c0 .183-.16.243-.249.243-.092 0-.252-.06-.252-.243 0-.197.16-.257.252-.257.088 0 .248.06.248.257Z"
    />
  </svg>
)
export default exclamation
