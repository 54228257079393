/** This file is auto generated by convertSvgToJsx.ts */

import React from 'react'
const information_medium = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M14.872 20.74h-1.125a2.249 2.249 0 0 1-2.248-2.248V10.87c0-.62-.504-1.124-1.125-1.124H9.25m.85-5.244h1.31m.09 0c0 .437-.31.747-.754.747A.718.718 0 0 1 10 4.502c0-.443.31-.753.747-.753.443 0 .753.31.753.753Z"
    />
  </svg>
)
export default information_medium
