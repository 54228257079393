/**
 * Web DrawerList Context
 *
 */

import React from 'react'

const DrawerListContext = React.createContext({})

export default DrawerListContext
