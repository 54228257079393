/** This file is auto generated by convertSvgToJsx.ts */

import React from 'react'
const calendar = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeWidth={1.5}
      d="M5.256 1v3.256M10.75 1v3.256M1 7.5V3.628a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1V7.5m-14 0V14a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V7.5m-14 0h14"
    />
  </svg>
)
export default calendar
